import React from 'react';
import './App.css';
import Security from './Components/Security.js';



function App() {
    return (
       <Security/>
    );
}
export default App;
