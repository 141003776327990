import React from 'react';
import {API_BASE_URL} from "../config";
import Logs from "../Logs";
import moment from 'moment';

class Project extends React.Component {
    constructor(props) {
        super(props);
        this.handelLogOut = this.handelLogOut.bind(this);

    }

    state = {
        projects: [],
        ShowLogs: false,
        LogId: 0,
        projectName: '',
        isTest: '',
        foundData: false,
        token: ''
    };


    GetProject(project) {
        this.setState({ShowLogs: true, LogId: project.Id, projectName: project.Name, isTest: project.Is_Test});
    }
    setSession(){
        let sessionIdFromSessionStorage = sessionStorage.getItem('mfucrkovkuevvsfdrjhlpibfshhkmdtuwymjmpsofqrrvcdblrpnkcrvjrcqblpwsmjeegoqwmxvsotauftxubwdbbogmcxgxyxbqeqvqgorkgompeelettrecovgzna');
        if(sessionIdFromSessionStorage !== null){
            this.GetAllProjects(sessionIdFromSessionStorage);
        } else {
            this.GetAllProjects(this.props.accessToken);
        }
    }

    GetAllProjects(sessionId) {
        fetch(API_BASE_URL + '/projects', {
            method: 'GET',
            headers: {
                'Accept': "application/json, text/plain",
                "Content-Type": "application/json; charset=UTF-8",
                "Authorization": "Bearer " + sessionId
            },
        }).then(result => {
            return result.json();
        }).then(project => {
            if (project.length !== 0) {
                this.setState({projects: project.data, foundData: true});
            }
        }).catch(() => {
            this.setState({foundData: false});
        });
    }

    handelLogOut(){
        this.Logout();
        try{
            this.props.onLogOut();
        }catch(ex){
            console.log('successfully logged out');
        }
    }

    Logout() {
        fetch(API_BASE_URL + '/logout', {
            method: 'POST',
            headers: {
                'Accept': "application/json, text/plain",
                "Content-Type": "application/json; charset=UTF-8",
                "Authorization": "Bearer " + sessionStorage.getItem('mfucrkovkuevvsfdrjhlpibfshhkmdtuwymjmpsofqrrvcdblrpnkcrvjrcqblpwsmjeegoqwmxvsotauftxubwdbbogmcxgxyxbqeqvqgorkgompeelettrecovgzna')
            },
        }).then(result => {
            return result.json();
        }).then(project => {
            sessionStorage.removeItem('mfucrkovkuevvsfdrjhlpibfshhkmdtuwymjmpsofqrrvcdblrpnkcrvjrcqblpwsmjeegoqwmxvsotauftxubwdbbogmcxgxyxbqeqvqgorkgompeelettrecovgzna');
        });
    }

    componentDidMount() {

        this.setSession();

    }

    renderProjects(projects) {

        return (
            this.state.ShowLogs ? <Logs dataFromParent={this.state.LogId} nameFromProject={this.state.projectName}
                                        isTestProject={this.state.isTest}/> :
                <div className="">
                    <div className="container">

                        <div className="entries">
                            <h1>All Projects</h1><br/>
                            <input type="submit" value="&lArr; Logout" className="backbutton" onClick={this.handelLogOut}/>
                            <div className="container">
                                <table className="table text-center">
                                    <thead>
                                    <tr>
                                        <th><h3><b>Id</b></h3></th>
                                        <th><h3><b>Last_Ping</b></h3></th>
                                        <th><h3><b>Name</b></h3></th>
                                        <th><h3><b>Version</b></h3></th>
                                        <th><h3><b>Customer_Name</b></h3></th>
                                        <th><h3><b>Is_Test</b></h3></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.projects.map(project =>
                                        <tr className="projectentry" id={project.Id} key={project.Id}
                                            onClick={() => this.GetProject(project)}>
                                            <td><b>{project.Id}</b></td>
                                            <td>{moment(project.Last_Ping).format('DD.MM.YYYY HH:mm:ss')}</td>
                                            <td>{project.Name}</td>
                                            <td>{project.Version}</td>
                                            <td>{project.Customer_Name}</td>
                                            <td>{project.Is_Test}</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
        )


    }

    render() {
        let contents = this.state.foundData
            ?
            this.renderProjects(this.state.projects)
            :
            <div className="container">
                <h1>Loading Data</h1>
            </div>
        ;

        return (
            <div>
                {contents}
            </div>
        );
    }
}

export default Project;