import React from 'react';
import {API_BASE_URL} from "./config";
import LogEntry from "./Components/LogEntry";
import Project from "./Components/Project";

class Logs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logs: [],
            fromDate:'',
            toDate:'',
            goBack: false,
            id:'',
            searchTerm: '',
        };
        this.GetSearchTerm = this.GetSearchTerm.bind(this);

        this.GetFromDate = this.GetFromDate.bind(this);
        this.GetToDate = this.GetToDate.bind(this);
        this.GoBack = this.GoBack.bind(this);
        this.ClearAllFilters = this.ClearAllFilters.bind(this);
        this.GetAllLogs();

    }
    ClearAllFilters(event){
        this.setState({fromDate:null,toDate:null,searchTerm:''});
    }
    GetToDate(event){
        this.setState({toDate:event.target.value});
    }
    GetFromDate(event){
        this.setState({fromDate:event.target.value});
    }
    GetCurrentDate(){
        var Today = new Date().toISOString().slice(0,10);
        var MonthBegin = new Date();
        MonthBegin.setDate(MonthBegin.getMonth()-1);
        var PastDate = MonthBegin.getFullYear() +"-"+MonthBegin.getMonth()+"-"+MonthBegin.getDay();
        var fromPastDate = new Date(PastDate).toISOString().slice(0,10);
        this.setState({fromDate:fromPastDate,toDate:Today});
    }

    GetSearchTerm(event) {
        this.setState({searchTerm: event.target.value});
    }


    GoBack() {
        this.setState({goBack: true});
    }

    GetAllLogs() {
        fetch(API_BASE_URL + '/logs/sort/' + this.props.dataFromParent, {
            method: 'GET',
            headers: {
                'Accept': "application/json, text/plain",
                "Content-Type": "application/json; charset=UTF-8",
                "Authorization":"Bearer "+sessionStorage.getItem('mfucrkovkuevvsfdrjhlpibfshhkmdtuwymjmpsofqrrvcdblrpnkcrvjrcqblpwsmjeegoqwmxvsotauftxubwdbbogmcxgxyxbqeqvqgorkgompeelettrecovgzna')

            },
        }).then(result => {
            return result.json();
        }).then(log => {
            this.setState({logs: log.data});
        });

    }
    CheckIfProjectIsProd(){
        if(this.props.isTestProject === 0){
            return "Prod";
        }
        return "Test";
    }
    componentDidUpdate() {

    }
    componentDidMount() {
        this.GetCurrentDate();
    }


    render() {
        return (
                this.state.goBack ? <Project/> :
                    <div className="entries">
                        <div className="container">
                            <h1>Project: {this.props.nameFromProject} <label className={this.CheckIfProjectIsProd() === 'Test' ? 'Test' : 'Prod'}>{this.CheckIfProjectIsProd()}</label></h1>
                            <p> Logs found: {this.state.logs.length}</p>
                            <input className='backbutton' type="submit" value="&lArr; Go Back " onClick={this.GoBack}/><br/>
                            <div className="filters">
                                <div className="f2">
                                    From: <input className="f13"type="date" placeholder="yyyy-mm-dd" value={this.state.fromDate || ""} onChange={this.GetFromDate}/>
                                </div>
                                <div className="f3">
                                    To:<input className="f13"type="date" placeholder="yyyy-mm-dd" value={this.state.toDate || ""} onChange={this.GetToDate}/>
                                </div>
                            </div><br/>
                            <div className="container">
                           <input type="text" className='searchterm' value={this.state.searchTerm || ""} placeholder="Enter search term" onChange={this.GetSearchTerm}/>
                          <button className="clearallfilters" onClick={this.ClearAllFilters}><b>X</b></button>
                            </div>

                            {/* eslint-disable-next-line react/jsx-no-undef */}
                            <LogEntry data={this.state.logs} keyword={this.state.searchTerm} fromDate={this.state.fromDate} toDate={this.state.toDate} />
                        </div>
                    </div>
        );
    }
}

export default Logs;
