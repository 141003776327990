import React from 'react';
import LogDetails from "./LogDetails";

class LogEntry extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            showPopup: false,
            id:''
        }
        this.togglePopup = this.togglePopup.bind(this);
        this.GetLogId = this.GetLogId.bind(this);
    }
    GetLogId(id) {
        this.setState({id:id});
        this.togglePopup();
    }
    togglePopup() {
        this.setState({
            showPopup:!this.state.showPopup
        });
    }

    GetLogsBySearchTerm(){
        const filteredarray = this.props.data;
        let searchTermLowerCase = this.props.keyword.toLowerCase();
        return (filteredarray.filter(log => {
            return (
                this.searchTermMatched(searchTermLowerCase, log.Message, log.Id, log.Error_ID) &&
                this.isDateGreaterThan(new Date(log.Time_Stamp), this.props.fromDate) &&
                this.isDateSmallerThan(new Date(log.Time_Stamp), this.props.toDate)
            );
        }));
    }

    searchTermMatched(searchTermLowerCase, logMessage, logId, logErrorId)
    {
        return (searchTermLowerCase === '' ||
            (logMessage.toLowerCase().indexOf(searchTermLowerCase) > -1 ||
            logId.toString().toLowerCase().indexOf(searchTermLowerCase) > -1 ||
            logErrorId.toString().toLowerCase().indexOf(searchTermLowerCase) > -1))
    }

    isDateGreaterThan(inputDate, fromDateString)
    {
        return ((fromDateString === null || fromDateString === '') || (inputDate >= new Date(fromDateString)));
    }

    isDateSmallerThan(inputDate, toDateString)
    {
        return ((toDateString === null || toDateString === '') || (inputDate <= new Date(toDateString)));
    }
    componentDidUpdate() {
    }

    render(){
        return(

            <table className="table text-center">
                <thead>
                <tr>
                    <th><h3><b>Id</b></h3></th>
                    <th><h3><b>Time_Stamp</b></h3></th>
                    <th><h3><b>Error_Id</b></h3></th>
                    <th><h3><b>Message</b></h3></th>
                    <th><h3><b>Log_Level</b></h3></th>
                </tr>
                </thead>
                <tbody>
                {this.GetLogsBySearchTerm().map(logs =>
                    <tr className="logentry" id={logs.Id} key={logs.Id}
                        onClick={() => this.GetLogId(logs.Id)}>
                        <td><b>{logs.Id}</b></td>
                        <td>{logs.Time_Stamp}</td>
                        <td>{logs.Error_ID}</td>
                        <td>{logs.Message}</td>
                        <td>{logs.Log_Level}</td>
                    </tr>
                )}
                </tbody>
                {this.state.showPopup ? <LogDetails
                    text='Click "Close Button" to hide popup'
                    closePopup={this.togglePopup.bind(this)}
                    id={this.state.id}
                /> : null}
            </table>
        )
    }
}
export default LogEntry;