import React from 'react';
import '../styles.css';
import {API_BASE_URL} from "../config";

class LogDetails extends React.Component {
    constructor(props){
        super(props);
            this.state = {
                log:[]
            }
            this.GetLogById();

    }
    GetLogById() {
        fetch(API_BASE_URL + '/logs/' + this.props.id, {
            method: 'GET',
            headers: {
                'Accept': "application/json, text/plain",
                "Content-Type": "application/json; charset=UTF-8",
                "Authorization":"Bearer "+sessionStorage.getItem('mfucrkovkuevvsfdrjhlpibfshhkmdtuwymjmpsofqrrvcdblrpnkcrvjrcqblpwsmjeegoqwmxvsotauftxubwdbbogmcxgxyxbqeqvqgorkgompeelettrecovgzna')
            },
        }).then(result => {
            return result.json();
        }).then(log => {
            this.setState({log: log.data});
        })

    }


    render(){
        return (
          <div className='popup'>
          <div className='popup\_inner'>
              <p></p>
              <div className='inner-content'>
                  <button onClick={this.props.closePopup}>X</button>
                  <h1>Details</h1>
                  <div className='b1'>
                      ID:<h3>{this.state.log.Id}</h3>
                  </div>
                  <div className='b2'>
                      Time_Stamp:<h3>{this.state.log.Time_Stamp}</h3>
                  </div>
                 <div className='b3'>
                      Error_ID:<h3>{this.state.log.Error_ID}</h3>
                 </div>
                  <div className='b4'>
                      Message:<h3>{this.state.log.Message}</h3>
                  </div>
                  <div className='b5'>
                      Log_Level:<h3>{this.state.log.Log_Level}</h3>
                  </div>
                  <div className='b6'>
                      Stack_Trace:<h3>{this.state.log.Stack_Trace}</h3>
                  </div>
              </div>
          </div>
          </div>
        );
    }
}
export default LogDetails;