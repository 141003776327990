import React from 'react';
import '../App.css';
import {API_BASE_URL} from "../config";
import Project from "./Project";

class Security extends React.Component {

    constructor(props) {
        super(props);
        this.state = {email: '', success: false, password: '',token:''};
        this.GetAnwser = this.GetAnwser.bind(this);
        this.Login = this.Login.bind(this);
        this.logOut = this.logOut.bind(this);
        this.GetPassword = this.GetPassword.bind(this);
    }

    GetAnwser(event) {
        this.setState({email: event.target.value});
    }

    GetPassword(event) {
        this.setState({password: event.target.value});
    }

    Login(event) {
        if (this.state.email !== '' && this.state.password !== '') {
            fetch(API_BASE_URL + '/login', {
                method: 'POST',
                headers: {
                    'Accept': "application/json, text/plain",
                    "Content-Type": "application/json; charset=UTF-8",
                    "mode": "no-cors"
                },
                body: JSON.stringify({
                    email: this.state.email,
                    password: this.state.password
                }),
            }).then(result => {
                return result.json();
            }).then(data => {
                if (data.access_token !== undefined) {
                    this.setState({success: true,token:data.access_token});
                    sessionStorage.setItem('mfucrkovkuevvsfdrjhlpibfshhkmdtuwymjmpsofqrrvcdblrpnkcrvjrcqblpwsmjeegoqwmxvsotauftxubwdbbogmcxgxyxbqeqvqgorkgompeelettrecovgzna', data.access_token);
                }else {
                    alert('Either username or password is wrong');
                }
            })
        } else {
            alert("Username or password field can't be empty");
        }

    }

    componentDidMount() {
        let test = sessionStorage.getItem('mfucrkovkuevvsfdrjhlpibfshhkmdtuwymjmpsofqrrvcdblrpnkcrvjrcqblpwsmjeegoqwmxvsotauftxubwdbbogmcxgxyxbqeqvqgorkgompeelettrecovgzna');
        if (test !== null) {
            this.setState({success: true});
        } else {
            this.setState({success: false});
        }
    }
    logOut(){
        this.setState({success: false,password:'',email:''});
    }



    render() {
        return (
            this.state.success ?
                <Project isLoggedIn={true} accessToken={this.state.token} onLogOut={this.logOut} />
                :
                <div className="container">
                    <div className="header">
                        <h1>Logging-<br/><span className="spanclass">App</span></h1>
                        <div className="body">
                            <p>Fill in the fields to continue</p><br/>
                        </div>
                        <div className="body1">
                            <input type="text" value={this.state.email} placeholder="Enter Email"
                                   onChange={this.GetAnwser}></input><br/>
                            <input type="password" value={this.state.password} placeholder="Enter Password"
                                   onChange={this.GetPassword}></input>
                        </div>
                        <input type="submit" value="Submit" className="submitbutton" onClick={this.Login}/>
                    </div>
                </div>
        );
    }
}

export default Security;